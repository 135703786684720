import "./assets/style/main.scss";
import React, { useEffect, Suspense, lazy } from "react";
import Lenis from "lenis";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Footer = lazy(() => import("./components/Footer"));
const GunMan = lazy(() => import("./components/GunMan"));
const Hero = lazy(() => import("./components/Hero"));
const OnePromise = lazy(() => import("./components/OnePromise"));
const SampleVideo = lazy(() => import("./components/SampleVideo"));
const WhatWeDo = lazy(() => import("./components/WhatWeDo"));
const ZoomImages = lazy(() => import("./components/ZoomImages"));
const WhatWeDonew = lazy(() => import("./components/WhatWeDoNew"));
const Slider = lazy(() => import("./components/Slider"));
const CombinedSection = lazy(() => import("./components/TextAnimation"));

const LoadingFallback = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

function App() {
  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  const HomePage = () => (
    <Suspense fallback={<LoadingFallback />}>
      <div>
        <Hero />
        <OnePromise />
        <WhatWeDonew />
        <ZoomImages />
        <WhatWeDo />
        <SampleVideo />
        <CombinedSection />
        <GunMan />
        <Footer />
      </div>
    </Suspense>
  );

  return (
    <Router>
      <Routes>
        <Route
          path="/slider"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <Slider />
            </Suspense>
          }
        />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
